// extracted by mini-css-extract-plugin
export const header = "layout-module--header--R1XBo";
export const site = "layout-module--site--w3AST";
export const siteContent = "layout-module--site-content--CFgOZ";
export const main = "layout-module--main--5VKR9";
export const footer = "layout-module--footer--rX0Dt";
export const githubIcon = "layout-module--githubIcon--AFb5s";
export const youtubeIcon = "layout-module--youtubeIcon--uCMgR";
export const footerContent = "layout-module--footerContent--U4YpZ";
export const copyright = "layout-module--copyright--7omyd";
export const buttons = "layout-module--buttons--fH9AM";